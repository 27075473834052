import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Container, MenuItem, Popover, Stack, Typography, Box, Switch, Pagination } from '@mui/material';
// components
// import { ProductSort, PostList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
// mock
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Iconify from '../components/iconify/Iconify';
// import PRODUCTS from '../_mock/products';
import { deletepost, getPost } from '../service/post.service';
import { PostList, ProductFilterSidebar, ProductSort, ProductCartWidget } from '../sections/@dashboard/products';


// ----------------------------------------------------------------------

export default function PostPage() {
  const navigate = useNavigate();
  const [page, setpage] = useState(1);
  const [id, setid] = useState('');
  const [open, setOpen] = useState(null);
  const [deletedpost, setdeletepost] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const [openFilter, setOpenFilter] = useState(false);
  const [posts, setposts] = useState([]);

  const handledeletePost = async () => {
    const deletedata = await deletepost(id);
    handleCloseMenu();
    if (deletedata.message === 'Unauthorized') {
      localStorage.setItem('token', '');
      navigate('/');
    }
    getdata();
  };
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  const getdata = async () => {
    try {
      setisLoading(true);
      const resp = await getPost();
      if (deletedpost) {
        setposts(
          resp.data.filter((item) => {
            setisLoading(false);
            return item.isDeleted === true;
          })
        );
      } else {
        setposts(
          resp.data.filter((item) => {
            setisLoading(false);
            return item.isDeleted === false;
          })
        );
      }
    } catch (error) {
      const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    console.log(message);
    toast.error(message)
      setisLoading(false);
     
    }
  };
  useEffect(() => {
    getdata();
  }, [deletedpost]);
  console.log(page);
  const handleOpenMenu = (event) => {
    setid(event.currentTarget.value);
    setOpen(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setid('');
    setOpen(null);
  };
  const handleChange = (e) => {
    setpage(e.target.innerText);
  };
  return (
    <>
      <Helmet>
        <title> Posts </title>
      </Helmet>


        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Posts
          </Typography>
          <Box component={'div'}>
            <Typography variant="p" gutterBottom>
              Deleted Posts
            </Typography>
            <Switch
              checked={deletedpost}
              onChange={() => {
                setdeletepost(!deletedpost);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
        </Stack>

        {/* <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductFilterSidebar
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort />
          </Stack>
        </Stack> */}

        <PostList
          posts={posts.slice((page - 1) * 10, (page - 1) * 10 + 10)}
          isloading={isLoading}
          handleOpenMenu={handleOpenMenu}
        />
        <Pagination
          sx={{ float: 'right', mt: 1 }}
          count={
            posts.length / 10 === parseInt(posts.length / 10, 10) * 10
              ? posts.length / 10
              : parseInt(posts.length / 10, 10) + 1
          }
          shape="rounded"
          page={page}
          onChange={handleChange}
        />
        {/* <ProductCartWidget /> */}


      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem> */}

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} onClick={handledeletePost} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
