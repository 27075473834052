import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';

import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Switch,
  Skeleton,
} from '@mui/material';
// components
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import TableLoading from '../components/table-loading/tableLoading';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
// import USERLIST from '../_mock/user';
import { deleteSelectedUser, getUsers } from '../service/user.service';

import { imgURL } from '../service/config';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'profile.image.file', label: 'Image', alignRight: false },
  { id: 'profile.fullName', label: 'Name', alignRight: false },
  { id: 'isCompleteProfile', label: 'Role', alignRight: false },
  { id: 'userType', label: 'Profile Complete', alignRight: false },
  { id: 'status', label: 'Posts', alignRight: false },
  { id: '_id' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.profile?.fullName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
export default function UserPage() {
  const navigate = useNavigate();
  const [id, setid] = useState('');
  const [open, setOpen] = useState(null);
  const [data, setdata] = useState([]);

  const [deleteUser, setdeletedUser] = useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isLoading, setIslaoading] = useState(false);

  const handleOpenMenu = (event) => {
    setid(event.currentTarget.value);
    setOpen(event.currentTarget);
  };
 

  const handleCloseMenu = () => {
    setid('');
    setOpen(null);
  };
  const handledeleteUser = async () => {
    try {
      setIsDeleteLoading(true);
      const deletedata = await deleteSelectedUser(id);
      if (deletedata.message === 'Unauthorized') {
        localStorage.setItem('token', '');
        navigate('/');
      }
      if(deletedata.status===true)
      {toast.success("User Deleted Successfully")
      handleCloseMenu()}
      getdata();
      setIsDeleteLoading(false);
    } catch (error) {
      setIsDeleteLoading(false);
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      console.log(message);
      toast.error(message);
      handleCloseMenu();
    }
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  // const userdata = useSelector((state) => state.userListState);
  const getdata = async () => {
    try {
      setIslaoading(true);
      const resp = await getUsers();

      if (resp.status === true) {
       
        if (deleteUser) {
          setdata(
            resp.data.filter((item) => {
              setIslaoading(false);
              return item.isDeleted === true;
            })
          );
        } else {
          setIslaoading(false);
          setdata(
            resp.data.filter((item) => {
              return item.isDeleted === false;
            })
          );
        }
      }
      // console.log("userdata",userdata)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      console.log(message);
      toast.error(message);
      setIslaoading(false);
    }
  };
  useEffect(() => {
    getdata();
  }, [deleteUser]);


  return (
    <>
      <Helmet>
        <title> User </title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          User
        </Typography>
        <Box component={'div'}>
          <Typography variant="p" gutterBottom>
            Deleted Users
          </Typography>
          <Switch
            checked={deleteUser}
            onChange={() => {
              setdeletedUser(!deleteUser);
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Box>
        {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button> */}
      </Stack>

      <Card>
        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />

              <TableBody>
                {isLoading? <TableLoading tableHeading={TABLE_HEAD}/>:""}
               <>
               {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  // const { _id, name, role, status, company, avatarUrl, isVerified } = row;
                  const selectedUser = selected.indexOf(row._id) !== -1;

                  return (
                    <TableRow hover key={row._id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                 
                       
                  
                      
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, row._id)} />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {row.profile?.image?.file ? (
                                <Box
                                  sx={{ width: 40, height: 40, borderRadius: '50px', margin: '12px' }}
                                  component="img"
                                  alt={row.profile?.fullName}
                                  src={imgURL + row.profile?.image?.file}
                                />
                              ) : (
                                <Avatar
                                  style={{ margin: '12px' }}
                                  sx={{
                                    bgcolor: stringToColor(row.profile?.fullName),
                                  }}
                                  children={
                                    row.profile?.fullName.split(' ').length > 1
                                      ? `${row.profile?.fullName.split(' ')[0][0]}${
                                          row.profile.fullName.split(' ')[1][0]
                                        }`
                                      : `${row.profile?.fullName.split(' ')[0][0]}`
                                  }
                                />
                              )}
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {row.profile?.fullName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{row.userType}</TableCell>

                          <TableCell align="left">{row.isCompleteProfile ? 'Complete' : 'Pending'}</TableCell>

                          <TableCell align="left">{row.profile?.post?.length}</TableCell>

                          {/* <TableCell align="left">
                          <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell> */}

                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={handleOpenMenu} value={row?.profile?._id}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                     
                    
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
               </>
              </TableBody>

              { isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

              {!isLoading && data?.length===0 && 
              <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          No User Found
                        </Typography>

                   
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              }
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,

            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem> */}

        <Button sx={{ color: 'error.main' }} onClick={handledeleteUser} disabled={isDeleteLoading}>
          <Iconify icon={'eva:trash-2-outline'} /> {isDeleteLoading ? 'Loading' : ' Delete'}
        </Button>
      </Popover>
    </>
  );
}
